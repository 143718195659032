import React from "react";
import { PageWrapper } from "~components/Core";

import HeaderButton from "~sections/marketing/Header";
import HeaderButtonBorder from "~sections/marketing/HeaderNo";

import DynamicComponent from "~components/dynamicComponent";
import SbEditable from "storyblok-react";
import FooterSix from "~components/Footer";
import SEO from "../components/Seo";

import useStoryblok from "../lib/storyblok";

import { graphql } from "gatsby";

export default function IndexPage({ data, location }) {
  let story = data.storyblokEntry;
  story = useStoryblok(story, location);

  const components = story.content.body.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });


  const header = {
    headerClasses: `site-header site-header--menu-start ${
      story.content.dark_mode ? "dark-header" : "light-header"
    } site-header--sticky`,
    containerFluid: story.content.header_container_mode,
    darkLogo: !story.content.dark_mode,

    // customLogo: Images.HeaderLogo,
    buttonBlock: (
      <div>
      <HeaderButtonBorder
      className="ms-auto d-none d-xl-inline-flex"
      btnOneText="LOG IN"
      mr="15px"
      mrLG="0"

    />
      <HeaderButtonBorder
      className="ms-auto d-none d-xl-inline-flex"
      btnOneText="PROVIDE SUPPORT"
      mr="15px"
      mrLG="0"
    />

      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="GET STARTED"
        mr="15px"
        mrLG="0"
      />
    </div>

    ),
  };

  return (
    <PageWrapper headerConfig={header}>
      <SEO title={story.content.seo_title} description={story.content.seo_title}/>
      <SbEditable content={story.content}>{components}</SbEditable>
      <FooterSix/>
    </PageWrapper>
  );
}

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
    }
  }
`;
