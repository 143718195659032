import React from "react";
import { Link } from "~components";
import styled from "styled-components/macro";
import { Box } from "~styled";

export const HeaderButtonWrapper = styled(Box)`

.reveal-header .btn{
  color: #59D5B2 !important;
}
  .btn {
    height: 42px;
    border-radius: 0px;
    
    border: 2px solid #59D5B2;
    font-size: 16px;
    font-weight: 400;
    

    white-space: nowrap;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: 0.25px;
    color: white;
    margin-left: 10px;
    &:hover {
      /* box-shadow: 0 0px 0px rgba(241, 139, 98, 0.3); */
    }
    &-2 {
      min-width: 129px;
      height: 42px;
      background-color: #f2f5fb;
      border-color: #f2f5fb;
    }
  }
`;
const HeaderButtonBorder = ({
  btnOneLink,
  btnTwoLink,
  btnOneText,
  btnTwoText,
  ...rest
}) => {
  return (
    <HeaderButtonWrapper {...rest}>
      <Link target="_blank" className="btn other-link" to={btnOneLink ? btnOneLink : "/"}>
        {btnOneText}
      </Link>
    </HeaderButtonWrapper>
  );
};

export default HeaderButtonBorder;

